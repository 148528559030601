<template>
  <base-page
    :title="$t('arPoolManage.eqCodeList')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.equipmentPoolList"
      :columns="columns"
      rowName="code64"
      v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <el-button type="text" @click="dialogOpen(row)">
          {{ $t('arPoolManage.addArPool') }}
        </el-button>
      </template>
    </table-list>
    <!-- 填写硬件码 -->
    <dialog-form-list
      v-if="showDialogFormList"
      :config="dialogConfig"
      :formData="dialogFormData"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
    />
  </base-page>
</template>

<script>
export default {
  data() {
    return {
      // 搜索
      formData: {},
      config: [
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:AR码(64位)
        {
          label: this.$t('arPoolManage.code64'),
          prop: 'code64',
        },
        // lang:AR码(32位)
        {
          label: this.$t('arPoolManage.code32'),
          prop: 'code32',
        },
        // 操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '160px',
        },
      ],
      // 填写硬件码
      dialogConfig: [
        // lang: 设备码
        {
          label: this.$t('arPoolManage.eqCode'),
          prop: 'code',
          rules: {
            noNull: true,
          },
        },
      ],
      dialogFormData: {
        code: '',
      },
      showDialogFormList: false,
    }
  },
  methods: {
    // 打开
    dialogOpen(row) {
      this.tableRow = row
      this.$store.commit('updateCmd', 'add')
      this.$store.commit(
        'updateDialogTitle',
        this.$t('arPoolManage.writeEqCode')
      )
      this.dialogFormData.code = ''
      this.showDialogFormList = true
    },
    // 取消
    dialogCancel() {
      this.showDialogFormList = false
    },
    // 确定
    dialogConfirm() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.poolAdd,
        data: {
          type: 'AR',
          code: this.dialogFormData.code,
          code64: this.tableRow.code64,
          code32: this.tableRow.code32,
        },
      }).then(() => {
        this.showDialogFormList = false
        // lang:添加成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.search()
      })
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (!this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'del':
          this.deleteMethod()
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.equipmentPoolDelete,
            data: this.tableRow,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
  },
}
</script>